import React, { useEffect, useMemo } from "react";
import "../../Content/stylesheet/common.css";
import Cadopslogo from "../../Content/Images/Logo/cadopslogo.svg";
import "../../Content/stylesheet/layout.css";
import Cookies from "universal-cookie";
import TitleBanner from "../Shared/Layout/TitleBanner";

function ContactUs(props) {
  const cookies = useMemo(() => new Cookies(), []);
  useEffect(() => {
    cookies.remove("authheader");
  }, [cookies]);

  return (
    <div className="navbar-new">
      <div className="ms-5 " style={{ margin: "18px 0px" }}>
        <img
          alt="CADOPS Logo"
          src={Cadopslogo}
          width="105px"
        ></img>
      </div>
      <TitleBanner
        heading={"Contact us"}
        subheading={""}
        infoText={""}
        breadcrumbArray={[]}
        showButton1={false}
        showButton2={false}
        showButton3={false}
      />
      <div className="marginleft5" style={{ marginTop: "15px" }}>
        <p>
          Dear{" "}
          {cookies.get("username")?.split(" ")[0] === undefined
            ? `user (${props.enterpriseID})`
            : `${cookies.get("username").split(" ")[0]} (${props.enterpriseID})`}
          ,
        </p>

        <p>
          Our records show you are not currently registered to access CADOPS.
          This may be because:
        </p>

        <ul>
          <li>You may not have accessed CADOPS in the last 6 months</li>
          <li>You have not been set up as a user in CADOPS</li>
        </ul>

        <p>
          If you require access to CADOPS, please create a ticket by clicking{" "}
          <span
            className="anchortag"
            onClick={() => {
              window.open(
                "https://myit.bpglobal.com/myit?id=myit_ticket_submission_form&ci_id=aeec6c930fd5db40ef9a4bfce1050e68"
              );
            }}
          >
            here
          </span>{" "}
          and provide us with the following information:
        </p>

        <ul>
          <li>User ID</li>
          <li>Email</li>
          <li>Full name</li>
          <li>Position</li>
          <li>Line manager</li>
          <li>Region</li>
          <li>Reason for access</li>
        </ul>

        <p>
          Alternatively, please send us an email to{" "}
          <span
            className="anchortag"
            onClick={() => {
              window.open("mailto:AirCadops@bp.com", "mail");
            }}
          >
            AirCadops@bp.com
          </span>
        </p>
        <p>
          If you have been added to the database, please click <span
            className="anchortag"
            onClick={() => {
              window.location.reload()
            }}
          > here</span> to refresh this page.
        </p>
        <p>Thank you</p>
        <p>Air bp CADOPS Support Team</p>
        <p>Error: {props.authResponse[0]}</p>
        {props.authResponse[1] !== "" && (
          <p>
            Group attributes: {props.authResponse[1].length === 0 ? "[ ]" : [props.authResponse[1]]}
          </p>
        )
        }
      </div>
    </div>
  );
}

export default ContactUs;
